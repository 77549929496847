const ALLOWED_DOMAINS = ['zhenshizhihui.com'];

/**
 * 检查当前域名是否在允许列表中
 * @param {string} [customHost] - 可选的自定义域名
 * @returns {boolean} 是否显示额外内容
 */
export const showExtra = (customHost) => {
  try {
    const host = customHost || window.location.host;
    if (!host) {
      console.warn('无法获取域名信息');
      return false;
    }
    
    return ALLOWED_DOMAINS.some(domain => host.includes(domain));
  } catch (error) {
    console.error('检查域名时发生错误:', error);
    return false;
  }
}